"use client";

import { FC } from "react";
import Link from "next/link";
import { ButtonType } from "components/atoms/Button/types";
import ScrollUp from "components/atoms/ScrollUp";
import SectionTitle from "components/atoms/SectionTitle";
import AboutSectionOne from "components/molecules/AboutSection";
import AboutSectionThree from "components/molecules/AboutSectionThree";
import AboutSectionTwo from "components/molecules/AboutSectionTwo";
import Features from "components/molecules/Features";
import Hero from "components/molecules/Hero";
import Pricing from "components/molecules/Pricing";
import Testimonials from "components/molecules/Testimonials";
import { aboutSectionSaveContent, aboutSectionSaveImages, aboutSectionShareContent, aboutSectionShareImages } from "utils/landingText";
import * as Styled from "./Landing.styled";
export const Landing: FC = () => {
  return <Styled.Main data-sentry-element="unknown" data-sentry-component="Landing" data-sentry-source-file="index.tsx">
            <ScrollUp data-sentry-element="ScrollUp" data-sentry-source-file="index.tsx" />
            <Hero data-sentry-element="Hero" data-sentry-source-file="index.tsx" />
            <Features data-sentry-element="Features" data-sentry-source-file="index.tsx" />
            <AboutSectionOne data-sentry-element="AboutSectionOne" data-sentry-source-file="index.tsx" />
            <AboutSectionTwo id="about-2" images={aboutSectionShareImages} content={aboutSectionShareContent} data-sentry-element="AboutSectionTwo" data-sentry-source-file="index.tsx" />
            <AboutSectionThree data-sentry-element="AboutSectionThree" data-sentry-source-file="index.tsx" />
            <AboutSectionTwo id="about-4" images={aboutSectionSaveImages} content={aboutSectionSaveContent} data-sentry-element="AboutSectionTwo" data-sentry-source-file="index.tsx" />
            <Testimonials data-sentry-element="Testimonials" data-sentry-source-file="index.tsx" />
            <Pricing data-sentry-element="Pricing" data-sentry-source-file="index.tsx" />
            <Styled.Section data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                <SectionTitle title="Get Started for Free" paragraph="Create an Account for Free and Start Generating Fantasy Art Today!" center data-sentry-element="SectionTitle" data-sentry-source-file="index.tsx" />
                <Link title="Get Started with Character Generation for free" href="/character" passHref data-sentry-element="Link" data-sentry-source-file="index.tsx">
                    <Styled.Button variant={ButtonType.SUCCESS} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                        🔥 Get Started For Free
                    </Styled.Button>
                </Link>
            </Styled.Section>
        </Styled.Main>;
};