// pages/index.tsx
import Link from "next/link";
import { ButtonType } from "components/atoms/Button/types";
import { Button, ButtonGroup, Container, Content, Description, Title, Wrapper } from "./Hero.styled";
const HomePage = () => {
  return <Container data-sentry-element="Container" data-sentry-component="HomePage" data-sentry-source-file="index.tsx">
            <Wrapper data-sentry-element="Wrapper" data-sentry-source-file="index.tsx">
                <Content data-sentry-element="Content" data-sentry-source-file="index.tsx">
                    <Title data-sentry-element="Title" data-sentry-source-file="index.tsx">
                        Instant AI Generated Character Art for Your RPGs
                    </Title>
                    <Description data-sentry-element="Description" data-sentry-source-file="index.tsx">
                        CharGen makes it simple to create art of your
                        characters, NPCs, monsters, and more without any fancy
                        prompting with our best AI Character and Monster
                        Generator.
                    </Description>
                    <ButtonGroup data-sentry-element="ButtonGroup" data-sentry-source-file="index.tsx">
                        <Link title="Get Started with Character Generation For Free" href="/character" passHref data-sentry-element="Link" data-sentry-source-file="index.tsx">
                            <Button variant={ButtonType.SUCCESS} data-sentry-element="Button" data-sentry-source-file="index.tsx">
                                🔥 Get Started For Free
                            </Button>
                        </Link>
                    </ButtonGroup>
                </Content>
            </Wrapper>
        </Container>;
};
export default HomePage;